/*
-----------------------------------------------------------------------
	Portfolio CSS
-----------------------------------------------------------------------
*/

.single-portfolio {
    display: block;
    position: relative;
    overflow: hidden;
    .thumbnail {
        position: relative;
        .overlay {
            position: relative;
            display: block;
            z-index: 1;
            &::before {
                position: absolute;
                z-index: 9;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                content: "";
                transition: all 0.5s ease 0s;
                opacity: 0;
                background-color: rgba(0, 0, 0, 0.5);
                cursor: crosshair;
            }
            img {
                width: 100%;
            }
        }
    }
    .content {
        position: absolute;
        z-index: 9;
        bottom: 0;
        left: 0;
        width: 100%;
        padding: 35px;
        transition: all 0.5s ease 0s;
        opacity: 0;
        // Responsive
        @media #{$lg-device} {
            padding: 15px;
        }
        .title {
            font-size: 24px;
            line-height: 1;
            font-family: $heading-font;
            font-weight: 700;
            margin-bottom: 15px;
            // Responsive
            @media #{$lg-device} {
                font-size: 18px;
            }
            a {
                display: block;
                color: #fff;
            }
        }
        .desc {
            font-size: 14px;
            line-height: 1.3;
            color: #cccccc;
            font-family: $body-font;
            margin-bottom: 0;
        }
    }
    &:hover {
        .thumbnail {
            .overlay {
                &::before {
                    opacity: 1;
                }
            }
        }
        .content {
            opacity: 1;
        }
    }
}

.portfolio-area {
    &.portfolio-default-area {
        padding: 60px 0 100px;
        @media #{$lg-device} {
            padding: 55px 0 100px;
        }
        @media #{$md-device} {
            padding: 60px 0 75px;
        }
        @media #{$sm-device} {
            padding: 50px 0 60px;
        }
        .container-fluid {
            padding: 0 60px;
            @media #{$xxl-device} {
                padding: 0 15px;
            }
        }
        .widget-instagram-area {
            margin-top: 115px;
            @media #{$md-device} {
                margin-top: 100px;
            }
            @media #{$xs-device} {
                margin-top: 85px;
            }
        }
    }
}

.portfolio-list {
    align-items: stretch;
    display: flex;
    .portfolio-item {
        align-items: center;
        display: flex;
        justify-content: center;
        padding: 54.5px 7.5px;
        position: relative;
        @include transition(0.3s);
        @media #{$lg-device} {
            padding: 44.5px 7.5px;
        }
        @media #{$md-device} {
            padding: 34.5px 7.5px;
        }
        @media #{$sm-device} {
            padding: 20px 7.5px;
        }
        @media #{$xs-device} {
            padding: 0 7.5px 35px;
        }
    }
}

.btn-portfolio {
    background-color: transparent;
    border: 0;
    font-size: 16px;
    line-height: 1.5;
    text-transform: uppercase;
    font-family: $secondary-font;
    color: #999999;
    letter-spacing: 5px;
    &:hover {
        color: $theme-color;
    }
}

.widget-instagram-area {
    color: $black;
    display: inline-flex;
    flex-direction: column;
    font-family: $font-current-theme2;
    font-size: 30px;
    line-height: 48px;
    letter-spacing: -0.1px;
    text-align: center;
    @include transition(0.3s);
    @media #{$sm-device} {
        font-size: 24px;
    }
    @media #{$xs-device} {
        font-size: 18px;
    }
    i {
        color: $black;
        font-size: 48px;
        margin-bottom: 21px;
    }
    label {
        position: relative;
        &:after {
            background-color: #535353;
            bottom: 7px;
            content: "";
            height: 2px;
            right: 0;
            position: absolute;
            width: 0;
            @include transition(0.3s);
            @media #{$sm-device} {
                bottom: 8px;
                height: 1px;
            }
            @media #{$xs-device} {
                bottom: 11px;
            }
        }
    }
    span {
        color: $black-999;
        font-family: $font-current-theme1;
        font-size: 18px;
        letter-spacing: 0;
    }
    &:hover,
    &:focus {
        color: $black;
        label {
            &:after {
                left: 0;
                width: 100%;
            }
        }
    }
}

.portfolio-single {
    padding: 90px 0 100px;
    @media #{$md-device} {
        padding: 75px 0 80px;
    }
    @media #{$sm-device} {
        padding: 50px 0 60px;
    }
    .inner-content {
        .content {
            margin-bottom: 80px;
            text-align: center;
            @media #{$sm-device} {
                margin-bottom: 60px;
            }
            @media #{$sm-device} {
                margin-bottom: 40px;
            }
            .category {
                color: $black-999;
                font-size: 18px;
                letter-spacing: 2px;
                margin-bottom: 34px;
                text-transform: uppercase;
                @media #{$lg-device} {
                    margin-bottom: 25px;
                }
                @media #{$md-device} {
                    font-size: 16px;
                    margin-bottom: 18px;
                }
                @media #{$sm-device} {
                    font-size: 14px;
                    margin-bottom: 12px;
                }
            }
            .title {
                font-size: 60px;
                line-height: 1;
                letter-spacing: -0.25px;
                @media #{$lg-device} {
                    font-size: 48px;
                }
                @media #{$md-device} {
                    font-size: 36px;
                }
                @media #{$sm-device} {
                    font-size: 30px;
                }
            }
        }
        .portfolio-info {
            border-bottom: 1px solid #e7e7e7;
            margin-bottom: 95px;
            padding-bottom: 85px;
            @media #{$md-device} {
                margin-bottom: 75px;
                padding-bottom: 65px;
            }
            @media #{$sm-device} {
                margin-bottom: 50px;
                padding-bottom: 20px;
            }
            @media #{$xs-device} {
                padding: 0 50px 20px;
            }
            @media #{$xxs-device} {
                padding: 0 15px 20px;
            }
            .info-item {
                color: $black;
                font-family: $font-current-theme2;
                font-size: 18px;
                letter-spacing: -0.25px;
                @media #{$md-device} {
                    font-size: 14px;
                    br {
                        display: none;
                    }
                }
                @media #{$sm-device} {
                    margin-bottom: 40px;
                }
                @media #{$xs-device} {
                    font-size: 16px;
                }
                span {
                    color: $black-999;
                    display: block;
                    font-family: $font-current-theme1;
                    letter-spacing: 0.5px;
                    line-height: 1;
                    margin-bottom: 24px;
                    text-transform: uppercase;
                    @media #{$sm-device} {
                        margin-bottom: 15px;
                    }
                }
                &.style-two {
                    line-height: 48px;
                    margin-top: -9px;
                    @media #{$lg-device} {
                        br {
                            display: none;
                        }
                    }
                    @media #{$md-device} {
                        line-height: $body-line-height;
                        margin-top: 0;
                    }
                    span {
                        position: relative;
                        top: 9px;
                        @media #{$md-device} {
                            top: 0;
                        }
                    }
                }
            }
        }
        .portfolio-content {
            font-size: 18px;
            line-height: 30px;
            letter-spacing: -0.15px;
            margin: 0 auto 95px;
            max-width: 870px;
            text-align: center;
            @media #{$md-device} {
                margin: 0 auto 75px;
            }
            @media #{$sm-device} {
                margin: 0 auto 55px;
            }
            p {
                margin-bottom: 0;
            }
            a {
                color: $theme-color;
                text-decoration: underline;
            }
        }
    }
    .thumb {
        &.style-two {
            align-items: center;
            padding-left: 200px;
            @media #{$lg-device} {
                padding-left: 100px;
            }
            @media #{$md-device} {
                padding-left: 80px;
            }
            @media #{$sm-device} {
                padding: 0 75px;
            }
            @media #{$xs-device} {
                padding: 0 15px;
            }
            @media #{$xxs-device} {
                padding: 0 10px;
            }
            img {
                &:last-child {
                    padding-right: 0;
                }
            }
        }
    }
    .social-icons {
        margin: 80px 0 80px;
        text-align: center;
        @media #{$md-device} {
            margin: 60px 0 60px;
        }
        @media #{$sm-device} {
            margin: 40px 0 40px;
        }
        span {
            color: $black;
            font-family: $font-current-theme2;
            font-weight: $font-weight-bold;
            letter-spacing: 0.15px;
            margin-right: 22px;
        }
        a {
            color: $black-999;
            font-size: 18px;
            margin-right: 13px;
            &:last-child {
                margin-right: 0;
            }
            .icofont-google-plus {
                font-size: 27px;
                position: relative;
                top: 3px;
            }
            &:hover {
                color: $black;
            }
        }
    }
    .btn-started {
        border: 1px solid $gray-ccc;
        border-radius: 50%;
        color: $black;
        display: inline-block;
        font-size: 14px;
        font-weight: $font-weight-bold;
        height: 300px;
        letter-spacing: 0.25px;
        line-height: 24px;
        margin-bottom: 100px;
        padding: 70px 55px;
        text-align: center;
        text-transform: uppercase;
        width: 300px;
        @include transition(0.3s);
        @media #{$md-device} {
            margin-bottom: 80px;
        }
        @media #{$sm-device} {
            margin-bottom: 60px;
        }
        @media #{$xs-device} {
            font-size: 11px;
            height: 200px;
            line-height: 20px;
            padding: 45px 30px;
            width: 200px;
        }
        span {
            color: $theme-color6;
            display: block;
            font-family: $font-current-theme2;
            font-size: 30px;
            font-weight: $font-weight-normal;
            letter-spacing: 0;
            line-height: 1;
            margin: 23px 0 31px;
            text-transform: capitalize;
            @include transition(0.3s);
            @media #{$xs-device} {
                font-size: 18px;
                margin: 15px 0;
            }
        }
        i {
            color: $black;
            font-size: 36px;
            @include transition(0.3s);
            @media #{$xs-device} {
                font-size: 24px;
            }
        }
        &:hover {
            color: $black;
            span {
                color: $black;
            }
            i {
                color: $black;
            }
        }
    }
    .portfolio-navigation {
        border-top: 1px solid #e7e7e7;
        padding-top: 64px;
        // Responsive
        @media #{$sm-device} {
            padding-top: 40px;
        }
    }
}

.portfolio-navigation {
    display: flex;
    justify-content: space-between;
    .prev,
    .next {
        color: $black;
        font-size: 16px;
        font-weight: $font-weight-bold;
        letter-spacing: 0;
        @media #{$xxs-device} {
            font-size: 14px;
        }
        a {
            align-items: center;
            color: $black-999;
            display: flex;
            font-size: 24px;
            font-weight: $font-weight-normal;
            letter-spacing: 0.25px;
            margin-bottom: 5px;
            margin-left: -10px;
            @media #{$md-device} {
                font-size: 20px;
                margin-bottom: 0;
            }
            @media #{$xxs-device} {
                font-size: 16px;
            }
            i {
                font-size: 40px;
                @media #{$md-device} {
                    font-size: 32px;
                }
                @media #{$xxs-device} {
                    font-size: 24px;
                }
            }
            &:hover {
                color: $black;
            }
        }
    }
    .next {
        text-align: right;
        a {
            justify-content: flex-end;
            margin-right: -8px;
        }
    }
}

.messonry-button {
    button {
        background-color: transparent;
        border: 0;
        padding: 0;
        border: 0 none;
        position: relative;
        span {
            position: relative;
            display: inline-block;
            padding: 5px 20px;
            &.filter-text {
                text-transform: uppercase;
                font-weight: 600;
                line-height: 1.58;
                font-size: 16px;
                color: $body-color;
                -webkit-transition: all 0.3s
                    cubic-bezier(0.645, 0.045, 0.355, 1);
                transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
            }
        }
        &:hover {
            span {
                &.filter-counter {
                    visibility: visible;
                    opacity: 1;
                    -webkit-transform: translate(-50%, -100%);
                    -ms-transform: translate(-50%, -100%);
                    transform: translate(-50%, -100%);
                }
                &.filter-text {
                    color: $theme-color;
                }
            }
        }
        &.is-checked {
            span {
                &.filter-text {
                    color: $theme-color;
                }
            }
        }
    }
}
